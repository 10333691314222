// import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

const target = process.env.REACT_APP_BUILD_TARGET || 'app';

switch (target) {
    case 'ingredient-checker':
        void import('./ingredient-checker/IngredientCheckerApp').then(
            ({ default: BuildTarget }) => {
                ReactDOM.render(<BuildTarget />, document.getElementById('root'));
            },
        );
        break;

    case 'attribute-guide':
        void import('./attribute-guide/App').then(({ default: BuildTarget }) => {
            ReactDOM.render(<BuildTarget />, document.getElementById('root'));
        });
        break;

    default:
        void import('./App').then(({ default: BuildTarget }) => {
            ReactDOM.render(<BuildTarget />, document.getElementById('root'));
        });
        break;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
